<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-12 mt-3">
                    <h1>Reporte Aportes Imputados</h1>
                </div>
                <div class="col-md-5 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Aportes Imputados</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 form-group">
                    <div class="row">
                        <div class="col-12">
                            <label>Selecciona una o varias Empresas y/o Sucursales y el Período a consultar, luego presiona la tecla Enter o el botón con el icono de Lupa:</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <app-selector-empresa [empresas]="empresas" (seleccionEmpresas)="seleccionarEmpresasSucursales($event)"></app-selector-empresa>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>Período Desde:</label>
                            <kendo-combobox [data]="listFecIni" [(ngModel)]="fecImpIni" (valueChange)="configuraFechas()" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinAportes" [suggest]="true" class="form-control" placeholder="Selecciona el Año" (keydown)="filtrarReporteKeydown($event)">
                            </kendo-combobox>
                            <span *ngIf="errorFiltro.fecImpIni" class="form-error"> 
                                Debes seleccionar Fecha Imputación Desde
                            </span>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>Período Hasta:</label>
                            <kendo-combobox [data]="listFecFin" [(ngModel)]="fecImpFin" (valueChange)="configuraFechas()" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinAportes" [suggest]="true" class="form-control" placeholder="Selecciona el Año" (keydown)="filtrarReporteKeydown($event)">
                            </kendo-combobox>
                            <span *ngIf="errorFiltro.fecImpFin" class="form-error"> 
                                Debes seleccionar Fecha Imputación Hasta
                            </span>
                        </div>

                        <div class="col-12 col-md-1 form-group mt-1">
                            <a (click)="filtrarReporte()" data-toggle="tooltip" ngbTooltip="Filtrar Resultados" class="btn btn-primary" style="margin-top:20px;">
                                <img src="../assets/img/busca.png" class="icon-md">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid mb-5">
        <div class="container">
            <div class="row">
                <div class="col-12 mt-5">
                    <h1>Aportes Imputados</h1>
                </div>
                <div *ngIf="empresasSucursalSeleccionadas.length > 1" class="col-12 mt-1">
                    <h4 style="text-align: justify;">
                        Usted ha seleccionado más de una Empresa y/o Sucursal, por lo tanto la información de aportes corresponde a todas las Empresas y/o Sucursales seleccionadas, separados según Empresa.
                    </h4>
                </div>
                <div *ngIf="aportes.length > 0" class="col-12">
                    <div *ngIf="permisosModulosAcciones.REPOAPOR.acciones.includes('ApoExpEXC_01') || permisosModulosAcciones.REPOAPOR.acciones.includes('ApoExpPDF_01')" class="dropdown">
                        <button class="btn btn-primary float-right mt-4 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="../assets/img/bajar.png" class="icon-md mr-1"> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a *ngIf="permisosModulosAcciones.REPOAPOR.acciones.includes('ApoExpPDF_01')" (click)="pdf.saveAs('Aportes.pdf')" class="dropdown-item">PDF</a>
                            <a *ngIf="permisosModulosAcciones.REPOAPOR.acciones.includes('ApoExpEXC_01')" (click)="exportToExcel()" class="dropdown-item">Excel</a>
                        </div>
                        <div class="col-md-2 col-lg-2 form-group float-right" style="margin-top: 20px;">
                            <label>Fecha Informe:</label>
                            <p>{{fechaActual}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-3">
                    <kendo-grid #grid="kendoGrid" [kendoGridBinding]="aportes" [group]="groups" style="width: 100%!important;" class="table table-striped">
                        <kendo-grid-column field="codigoAporte" title="Nº Aporte"></kendo-grid-column>
                        <kendo-grid-column field="fechaImputacion" title="Fecha Imputación"></kendo-grid-column>
                        <kendo-grid-column field="oficinaVentas" title="Oficina Ventas"></kendo-grid-column>
                        <kendo-grid-column field="tipoTransaccion" title="Tipo Transacción"></kendo-grid-column>
                        <!--<kendo-grid-column field="fechaTransaccion" title="Fecha Transacción"></kendo-grid-column>-->
                        <kendo-grid-column field="nroDocumento" title="Nro. Documento"></kendo-grid-column>
                        <kendo-grid-column field="banco" title="Banco"></kendo-grid-column>
                        <kendo-grid-column field="monto" title="Monto"></kendo-grid-column>
                        <kendo-grid-column title="Contactar">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <a data-toggle="tooltip" class="btn btn-primary btn-sm" ngbTooltip="Haz clic para contactar al Ejecutivo Comercial" container="body" (click)="mostrarContactoEjecutivo(dataItem)">
                                    <img src="../assets/img/correo.png" class="icon-md">
                                </a>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-messages noRecords="No se encontraron registros">
                        </kendo-grid-messages>
                    </kendo-grid>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-5 float-right text-right">
                    <a [routerLink]="['/home-reportes']" class="btn btn-primary" data-toggle="tooltip" ngbTooltip="Volver atrás"><img src="../assets/img/volver.png" class="icon-md mr-1">Volver</a>
                </div>
            </div>
        </div>
    </div>

    <div style="position: absolute; left: -10000px; top: 0;">
        <kendo-pdf-export #pdf paperSize="A4" margin="1cm" [scale]="0.53">
            <div style="width: 1000px;">
                <div class="row">
                    <div class="col-md-2 col-lg-2 form-group float-left" style="margin-top: 20px;">
                        <label>Fecha Informe:</label>
                        <p>{{fechaActual}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-2 text-center titulo">
                        Nº Aporte
                    </div>
                    <div class="col-2 text-center titulo">
                        Fecha Imputación
                    </div>
                    <div class="col-2 text-center titulo">
                        Oficina Ventas
                    </div>
                    <div class="col-2 text-center titulo">
                        Tipo Transacción
                    </div>
                    <div class="col-2 text-center titulo">
                        Nro. Documento
                    </div>
                    <div class="col-1 text-center titulo">
                        Banco
                    </div>
                    <div class="col-1 text-center titulo">
                        Monto
                    </div>
                </div>
                <div *ngFor="let empresa of aportesPdf">
                    <div class="row">
                        <div class="col-12 empresa">
                            {{empresa.empresa}}
                        </div>
                    </div>
                    <div class="row" *ngFor="let aportes of empresa.aportes; let i = index">
                        <div class="col-2" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.codigoAporte}}
                        </div>
                        <div class="col-2" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.fechaImputacion}}
                        </div>
                        <div class="col-2" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.oficinaVentas}}
                        </div>
                        <div class="col-2" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.tipoTransaccion}}
                        </div>
                        <div class="col-2" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.nroDocumento}}
                        </div>
                        <div class="col-1" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.banco}}
                        </div>
                        <div class="col-1" [ngClass]="(i%2) ? 'linea' : ''">
                            {{aportes.monto}}
                        </div>
                    </div>
                </div>
            </div>
        </kendo-pdf-export>
    </div>
</main>

<ng-template #refModalContactar let-c="close" let-d="dismiss">
    <form #formContacto="ngForm" (ngSubmit)="enviarContacto(formContacto);" novalidate>
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">Contactar</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d()">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <p>Ingresa el mensaje para enviar al Ejecutivo Comercial de la Empresa:</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-3">Nº Aporte:</div>
                    <div class="col-md-8 col-lg-9"><span class="font-weight-bold ml-2">{{contactoAporte.codigoAporte}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-3">Empresa:</div>
                    <div class="col-md-8 col-lg-9">
                        <span class="font-weight-bold ml-2">
                        {{contactoAporte.codigoEmpresa}} - {{contactoAporte.empresa}}
                    </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-3">Ejecutivo Comercial:</div>
                    <div class="col-md-8 col-lg-9"><span class="font-weight-bold ml-2">{{contactoAporte.ejecutivoComercial}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-3">Usuario:</div>
                    <div class="col-md-8 col-lg-9"><span class="font-weight-bold ml-2">{{contactoAporte.usuario}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-3">Email:</div>
                    <div class="col-md-8 col-lg-9"><span class="font-weight-bold ml-2">{{contactoAporte.usuarioMail}}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 form-group">
                        <label>Mensaje:</label>
                        <textarea type="text" class="form-control" [(ngModel)]="contactoAporte.mensaje" id="observacion" name="observacion" #observacion="ngModel" required></textarea>
                        <span *ngIf="observacion.invalid && (formContacto.submitted || observacion.touched)" class="form-error">
                            <span *ngIf="formContacto.hasError('required', 'observacion')">Debes ingresar un mensaje.</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" id="btnGuardarEdicion" name="buttonCancelar"><img src="assets/img/ok.png" class="icon-md">
                    Enviar
                </button>
            </div>
        </div>
    </form>
</ng-template>