export class EmpresaModel {
    public Codigo:number = null;
    public Rut:string = null;
    public Nombre:string = null;
    public Holding:string = null;
    public Direccion:string = null;
    public Comuna:string = null;

    constructor(empresaJson?: any) {
        if(empresaJson){
            this.Codigo = empresaJson.Codigo || null;
            this.Rut = empresaJson.Rut || null;
            this.Nombre = empresaJson.Codigo == empresaJson.SucursalCodigo ? empresaJson.Nombre : `${empresaJson.Nombre} / ${empresaJson.Sucursal}`; ;
            this.Holding = empresaJson.Holding || null;
            this.Direccion = empresaJson.Direccion || null;
            this.Comuna = empresaJson.Comuna || null;
        }
    }
}
