import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckableSettings, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { NgForm } from '@angular/forms';
import { of, Observable } from 'rxjs';

// Services
import { ClienteComercialService } from '../../services/cliente-comercial.service';
import { AportesService } from '../../services/aportes.service';
import { LoggedInCallback } from '../../services/cognito.service';
import { UserLoginService } from '../../services/user-login.service';
import { NotificacionesService } from '../../services/notificaciones.service';

// Models
import { EmpresasSucursalModel } from '../../models/empresasSucursal.model';
import { AportesModel } from '../../models/aportes.model';

// Shared
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { environment } from 'src/environments/environment';
import { ContactoAportesModel } from 'src/app/models/contactoAportes';
import { PermisosCliComLocalStorageModel } from 'src/app/models/permisosCliComLocalStorage';
import { UserDataLocalStorageModel } from 'src/app/models/userDataLocalStorage';

@Component({
  selector: 'app-rep-aportes-imputados',
  templateUrl: './rep-aportes-imputados.component.html',
  styleUrls: ['./rep-aportes-imputados.component.scss'],
  providers: [GlobalService]
})
export class RepAportesImputadosComponent implements OnInit, LoggedInCallback {
  @ViewChild('refModalContactar', { static: false}) mdlEditar: TemplateRef<any>;
  urlGestion: string = environment.url_gestor;

  modalRef: NgbModalRef;

  min: number = null;
  max: number = null;
  fechaActual: string  = [
    new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
    (new Date().getMonth() + 1).toString().length === 1 ?
      '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
    new Date().getFullYear()].join('/');

  sinAportes = false;

  empresasSucursales: any[] = [];
  parsedData: any[] = [];
  checkedKeys: any[] = [];
  searchTerm = '';
  
  nombreCC: string;
  empresasSucursalSeleccionadas: number[] = [];
  empresas: EmpresasSucursalModel[] = [];
  fecImpIni: number = null;
  fecImpFin: number = null;

  errorFiltro = {
    empresaSucursal: false,
    fecImpIni: false,
    fecImpFin: false
  };

  groups: GroupDescriptor[] = [{ field: 'Empresa'}];
  aportes: AportesModel[] = [];
  aportesPdf: any[] = [];
  contactoAporte: ContactoAportesModel = new ContactoAportesModel();

  listFecIni: Array<string> = [];
  listFecFin: Array<string> = [];

  permisosModulosAcciones: any = {};
  permisos: Array<PermisosCliComLocalStorageModel>;
  userData: UserDataLocalStorageModel;


  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private globalService: GlobalService,
    private clienteComercialService: ClienteComercialService,
    private aportesService: AportesService,
    private notificacionesService: NotificacionesService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private userLoginService: UserLoginService
  ) {
      console.log('RepAportesImputadosComponent: constructor');
  }

  /**
   * OnInit Event
   */
  ngOnInit() {
    console.log('RepAportesImputadosComponent: OnInit');
    this.userLoginService.isAuthenticated(this);
    this.userData = this.localStorageService.get('userData');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
        this.permisos = this.localStorageService.get('permisos');
        this.userData = this.localStorageService.get('userData');

        if (this.permisosModulosAcciones.REPOAPOR) {
          this.initListadoEmpresas();
        } else {
          this.toastr.error(`No estás autorizado para ingresar a esta página`, GLOBALS.TOASTER.TITLE_ERROR);
          this.router.navigate(['/']);
        }

    }
  }

  /**
   * Inicializa el listado de Empresas
   */
  initListadoEmpresas() {
    this.spinner.show();

    const sucursalesAutorizadas = this.permisos.map((item) => item.data.sucursalCodigo).join(',');

    if (sucursalesAutorizadas) {
      this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
        .subscribe(async (result) => {

            if (result.rows.length > 0) {
              this.empresas = result.rows.map((empresa) => new EmpresasSucursalModel(empresa));

              let index = 1;
              let codigoEmpresa: number;
              let rama: any;
              for (const empresa of this.empresas) {
                if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    value: empresa.codigoSucursal,
                    items: []
                  };
                } else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                  if (index !== 1) {
                    this.empresasSucursales.push(rama);
                  }

                  codigoEmpresa = empresa.codigoEmpresa;
                  rama = {
                    text: empresa.rutRazonSocial,
                    items: [{
                      text: empresa.sucursal,
                      value: empresa.codigoSucursal
                    }]
                  };
                } else {
                  rama.items.push({
                    text: empresa.sucursal,
                    value: empresa.codigoSucursal
                  });
                }

                if (index === this.empresas.length) {
                  this.empresasSucursales.push(rama);
                }

                index++;
              }

              this.parsedData = this.empresasSucursales;
            } else {
              this.toastr.warning(`No hay información de Empresas / Sucursales en Reportes`, `Advertencia`);
            }

            this.spinner.hide();
          }, error => {
            console.log(error);
            this.spinner.hide();
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        );
    } else {
      this.toastr.warning(`No hay Empresas / Sucursales en Cliente Comercial`, `Advertencia`);
      this.spinner.hide();
    }
  }


  /**
   * Busca las fechas de Inicio y Fin de los aportes al cambiar la selección
   */
  inicializaFechas() {
    this.fecImpIni = null;
    this.fecImpFin = null;
    this.sinAportes = false;

    this.errorFiltro = {
      empresaSucursal: false,
      fecImpIni: false,
      fecImpFin: false
    };
    if (this.empresasSucursalSeleccionadas.length > 0) {
      const codigosEmpresa = this.empresasSucursalSeleccionadas.join(',');

      this.spinner.show();
      this.aportesService.obtenerFechasAportes(codigosEmpresa).subscribe(
        (result: any) => {
          this.spinner.hide();

          if (result.rows[0].minanio === null) {
            this.sinAportes = true;
          } else {
            this.min = result.rows[0].minanio;
            this.max = result.rows[0].maxanio;

            this.fecImpIni = this.min;
            this.fecImpFin = this.max;

            this.listFecIni = [];
            this.listFecFin = [];
            for (let z = this.min; z <= this.max; z++) {
              this.listFecIni.push(z.toString());
              this.listFecFin.push(z.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.spinner.hide();
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      );
    } else {
      if (this.empresasSucursales.length > 0) {
        this.errorFiltro.empresaSucursal = true;
      }
    }
  }

  /**
   * Configura las fechas de inicio y fin seleccionables cuando cambia el valor
   */
  configuraFechas() {
    this.spinner.show();

    this.listFecIni = [];
    this.listFecFin = [];

    for (let z = this.min; z <= (this.fecImpFin !== undefined ? this.fecImpFin : this.max); z++) {
      this.listFecIni.push(z.toString());
    }

    for (let z = (this.fecImpIni !== undefined ? this.fecImpIni : this.min); z <= this.max; z++) {
      this.listFecFin.push(z.toString());
    }

    this.spinner.hide();
  }

  seleccionarEmpresasSucursales(sucursales:number[]){
    this.empresasSucursalSeleccionadas = sucursales;
    this.inicializaFechas();
  }

  /**
   * Filtra el Reporte
   */
  filtrarReporte() {
    this.errorFiltro = {
      empresaSucursal: false,
      fecImpIni: false,
      fecImpFin: false
    };

    if (this.empresasSucursalSeleccionadas.length === 0) {
      this.errorFiltro.empresaSucursal = true;
      return;
    } else {
      if (this.fecImpIni === undefined || this.fecImpIni === null) {
        this.errorFiltro.fecImpIni = true;
      }
      if (this.fecImpFin === undefined || this.fecImpFin === null) {
        this.errorFiltro.fecImpFin = true;
      }

      if (this.fecImpIni === undefined || this.fecImpIni === null || this.fecImpFin === undefined || this.fecImpFin === null) {
        return;
      }
    }

    this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
    const filtrosReporte = {
      cliComercial:this.nombreCC,
      empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
      fecImpIni: this.fecImpIni,
      fecImpFin: this.fecImpFin
    };

    this.spinner.show();
    this.aportesService.reporteAportes(filtrosReporte).subscribe(
      (result: any) => {
        this.spinner.hide();

        this.aportes = result.rows.map((aporte: any) => {
          return new AportesModel(aporte);
        });

        // Arma la estructura necesaria para displegar el PDF
        this.aportesPdf = [];
        let empresa = '';
        for (const aporte of this.aportes) {
          if (aporte.Empresa !== empresa) {
            empresa = aporte.Empresa;

            this.aportesPdf.push({
              empresa,
              aportes: []
            });
          }

          this.aportesPdf.find(x => x.empresa === empresa).aportes.push(aporte);
        }
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
      }
    );
  }

  /**
   * Metodo para exportar a Excel
   * @param grid Grid
   */
  public exportToExcel(): void {
    this.spinner.show();

    const workbook = new Workbook({
      sheets: [{
        columns: [],
        rows: [{ cells: [{}] }]
      }]
    });

    workbook.options.sheets[0].name = 'Aportes';

    // workbook.options.sheets[0].rows[0].cells[0].colSpan = 8;
    workbook.options.sheets[0].rows[0].cells[0].colSpan = 7;
    workbook.options.sheets[0].rows[0].cells[0].value = `Fecha Informe: ${this.fechaActual}`;

    workbook.options.sheets[0].rows.push({ cells: [] });
    // for (let z = 0; z < 8; z++) {
    for (let z = 0; z < 7; z++) {
      let columna: string;
      switch (z) {
        case 0:
          columna = 'Nº Aporte';
          break;
        case 1:
          columna = 'Fecha Imputación';
          break;
        case 2:
          columna = 'Oficina Ventas';
          break;
        case 3:
          columna = 'Tipo Transacción';
          break;
        // case 4:
        //   columna = 'Fecha Transacción';
        //   break;
        case 5:
          columna = 'Nro. Documento';
          break;
        case 6:
          columna = 'Banco';
          break;
        default:
          columna = 'Monto';
          break;
      }
      workbook.options.sheets[0].columns.push({ autoWidth: true });
      workbook.options.sheets[0].rows[1].cells.push({
        background: '#112540',
        bold: true,
        color: '#ffffff',
        value: columna
      });
    }

    let codigoEmpresa = '';
    for (const aporte of this.aportes) {
      if (codigoEmpresa !== aporte.codigoEmpresa) {
        codigoEmpresa = aporte.codigoEmpresa;
        workbook.options.sheets[0].rows.push({
          cells: [
            // { value: `Empresa: ${aporte.Empresa}`, background: '#ededed', colSpan: 8 }
            { value: `Empresa: ${aporte.Empresa}`, background: '#ededed', colSpan: 7 }
          ]
        });
      }

      workbook.options.sheets[0].rows.push({
        cells: [
          { value: aporte.codigoAporte },
          { value: aporte.fechaImputacion },
          { value: aporte.oficinaVentas },
          { value: aporte.tipoTransaccion },
          // { value: aporte.fechaTransaccion },
          { value: aporte.nroDocumento },
          { value: aporte.banco },
          { value: aporte.monto, textAlign: 'right' }
        ]
      });
    }

    this.spinner.hide();

    workbook.toDataURL().then(dataUrl => {
      saveAs(dataUrl, 'Aportes.xlsx');
    });
  }

  /**
   * Muestra Modal con Contacto del Ejecutivo
   * @param aporte AportesModel
   */
  mostrarContactoEjecutivo(aporte: AportesModel) {
    this.spinner.show();
    this.clienteComercialService.obtenerContacto(aporte.codigoSucursal).subscribe(
      async (result: any) => {
        if (result.find(x => x.tipo.toString() === 'Ejecutivo Consultor') === undefined) {
          this.toastr.warning('Empresa no tiene un Ejecutivo Comercial asignado', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          this.contactoAporte.codigoEmpresa = aporte.codigoEmpresa;
          this.contactoAporte.empresa = aporte.Empresa;
          this.contactoAporte.codigoAporte = aporte.codigoAporte;
          this.contactoAporte.ejecutivoComercial = result[0].nombre;
          this.contactoAporte.ejecutivoComercialMail = result[0].mail;
          this.contactoAporte.asistenteComercial = result[1] !== undefined ? result[1].nombre : null;
          this.contactoAporte.asistenteComercialMail = result[1] !== undefined ? result[1].mail : null;
          this.contactoAporte.usuario = this.userData.nombre;
          this.contactoAporte.usuarioMail = this.userData.email;
          this.contactoAporte.mensaje = '';

          this.modalRef = this.modalService.open(this.mdlEditar, {size: 'lg', backdrop: 'static'});
        }

        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
      }
    );
  }

  /**
   * Envía notificación al Ejecutivo Comercial
   * @param form Formulario
   */
  enviarContacto(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.spinner.show();
    this.notificacionesService.enviarNotificacionContAportes(this.contactoAporte).subscribe(
      () => {
        this.toastr.success('Mensaje Enviado al Ejecutivo Comercial', GLOBALS.TOASTER.TITLE_OK);
        this.modalRef.close();
        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
      }
    );
  }

  /**
   * Filtra Reporte al presionar tecla Enter
   * @param event Evento
   */
  filtrarReporteKeydown(event) {
    if (event.key === 'Enter') {
      this.filtrarReporte();
    }
  }
}
